import React, { useState, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { useParams } from "react-router-dom";

function Videos() {
  const { joinCode } = useParams();

  const [webcamActive, setWebcamActive] = useState(false);

  var roomId = joinCode;

  console.log(joinCode);

  const localRef = useRef();
  const remoteRef = useRef();

  // Initialize WebRTC and Firebase Realtime Database
  const servers = {
    iceServers: [
      {
        urls: [
          "stun:stun1.l.google.com:19302",
          "stun:stun2.l.google.com:19302",
        ],
      },
    ],
    iceCandidatePoolSize: 10,
  };

  const pc = new RTCPeerConnection(servers);
  const database = firebase.database();

  // Rest of the Videos component
  // ... (Use your original Videos component code here)

  const setupSources = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
    const remoteStream = new MediaStream();

    localStream.getTracks().forEach((track) => {
      pc.addTrack(track, localStream);
    });

    pc.ontrack = (event) => {
      event.streams[0].getTracks().forEach((track) => {
        remoteStream.addTrack(track);
      });
    };

    localRef.current.srcObject = localStream;
    remoteRef.current.srcObject = remoteStream;

    setWebcamActive(true);
    const callRef = database.ref("calls").child(joinCode); // Reference the specific call

    pc.onicecandidate = (event) => {
      event.candidate &&
        callRef.child("answerCandidates").push(event.candidate.toJSON()); // Save ICE candidates
    };

    const callData = await callRef
      .once("value")
      .then((snapshot) => snapshot.val());

    const offerDescription = callData.offer;
    await pc.setRemoteDescription(new RTCSessionDescription(offerDescription));

    const answerDescription = await pc.createAnswer();
    await pc.setLocalDescription(answerDescription);

    const answer = {
      type: answerDescription.type,
      sdp: answerDescription.sdp,
    };

    await callRef.update({ answer });

    callRef.child("offerCandidates").on("child_added", (snapshot) => {
      const candidate = new RTCIceCandidate(snapshot.val());
      pc.addIceCandidate(candidate);
    });

    // if (roomId) {
    //   const roomRef = database.ref("calls").child(roomId);
    //   await roomRef.child("answerCandidates").remove();
    //   await roomRef.child("offerCandidates").remove();
    //   await roomRef.remove();
    // }

    pc.onconnectionstatechange = (event) => {
      if (pc.connectionState === "disconnected") {
        hangUp();
      }
    };
  };

  const hangUp = async () => {
    pc.close();

    if (roomId) {
      const roomRef = database.ref("calls").child(roomId);
      await roomRef.child("answerCandidates").remove();
      await roomRef.child("offerCandidates").remove();
      await roomRef.remove();
    }

    window.location.reload();
  };

  // useEffect(() => {
  //   setupSources();
  // }, []);

  return (
    <div className="videos">
      <video ref={localRef} autoPlay playsInline className="local" muted />
      <video ref={remoteRef} autoPlay playsInline className="remote" />

      {!webcamActive && (
        <div className="modalContainer">
          <div className="modal">
            <h3>Turn on your camera and microphone and start the call</h3>
            <div className="container">
              <button id="cancel" className="secondary">
                <a href="/">Cancel</a>
              </button>
              <button id="start" onClick={setupSources}>
                Start
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Videos;
